import React from "react"
import GoogleMapReact from "google-map-react"
import { Marker } from "./Marker"

export const MapBase = ({
  center = {
    lat: 0,
    lng: 0,
    text: "Property Marker",
  },
  zoom = 14,
  poi,
}) => {
  const mapOptions = {
    styles: [
      {
        featureType: "poi",
        elementType: "labels.text",
        stylers: [
          {
            visibility: "on",
          },
        ],
      },
    ],
  }

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: "50vh", width: "100%" }}>
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.GATSBY_GOOGLE_MAPS_GEOCODING_API_KEY,
        }}
        defaultCenter={center}
        defaultZoom={zoom}
        options={mapOptions}
      >
        <Marker {...center} markerType="pin" />

        {poi &&
          poi.map(poi => {
            return (
              <Marker
                key={poi.id}
                lat={poi.location.lat}
                lng={poi.location.lon}
                text={poi.name}
                markerType="circle"
              />
            )
          })}
      </GoogleMapReact>
    </div>
  )
}
