import React from "react"
import styled from "styled-components"
import PinPrimary from "../../images/map-markers/PinPrimary.svg"

const StyledMarker = styled.div`
background:red;

  .pin {
    position: relative !important;
    top: 50% !important;
    left: 50% !important;
    background-size: 40px 40px !important;
    width: 40px !important;
    height: 40px !important;
    user-select: none !important;
    transform: translate(-50%, -90%) !important;
    z-index: 999;
  }
  .pin-primary{
    background: url("${PinPrimary}");
  }


  /*Circle Marker*/
  .top-circle {
    position: absolute;
    width: 10px;
    height: 10px;
    opacity: 1;
    z-index: 10;
    background-color: ${props => props.theme.primary};
    left: 50%;
    top: 50%;
    border-radius: 100%;
    margin: -5px 0px 0px -5px;
  }

  /* .bottom-circle {
    position: absolute;
    width: 60px;
    height: 60px;
    opacity: 1;
    z-index: 9;
    background-color: ${props => props.theme.primary};
    opacity: 0.2;
    left: 50%;
    top: 50%;
    border-radius: 100%;
    margin: -30px 0px 0px -30px;
  } */

  .label {
    width: max-content;
    background-color: white;
    color: ${props => props.theme.primary};
    padding: 5px 5px 5px 2px;
    border: 1px solid ${props => props.theme.primary};
    
    transform: translate(20px, -50%);

    :after {
    content: '';
    display: block;
    position: absolute;
    top: 3px;
    left:-10px;
    width: 18px;
    height: 17px;
    background: #FFFFFF;
    border-left:1px solid ${props => props.theme.primary};
    border-top:1px solid${props => props.theme.primary};
    transform:rotate(-45deg);
    z-index:-1;
    
}
}
`

export const Marker = props => {
  const { text, markerType } = props

  if (markerType === "circle") {
    return (
      <StyledMarker title={text}>
        <div className="top-circle"></div>
        {/* <div className="bottom-circle"></div> */}
        <div className="label">{text}</div>
      </StyledMarker>
    )
  }

  //markerType is pin
  return (
    <StyledMarker title={text}>
      <div className="pin pin-primary"></div>
    </StyledMarker>
  )
}
