import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import { HeroCTAForm } from "../components/Hero/HeroCTAForm"
import { Container, Row, Col } from "react-bootstrap"
import { Icon } from "../components/IconFactory/Icons"
import {
  CurveCarveTop,
  CurveCarveBottom,
  CurveBulgeBottom,
} from "../components/Curves/Curve"
import { HR } from "../components/Typography/Seperators"
import styled from "styled-components"
import { MapBase } from "../components/Map/MapBase"
// import { AccordionCollection } from "../components/Accordion/AccordionCollection"
import { CallMeBackFull } from "../components/Misc/CallMeBackFull"
import { PropertyPhotos } from "../components/Property/PropertyPhotos"
import { AccordionSquared } from "../components/Accordion/AccordionSquared"
import { GetDetails } from "../components/Misc/GetDetails"
import { FaCircle } from "react-icons/fa"
import { MarkdownCommon } from "../components/Markdown/MarkdownCommon"
import PropertyEnquiryForm from "../components/Forms/PropertyEnquiryForm"

const StyledHighlights = styled.div`
  text-align: center;

  svg {
    width: 30px;
    height: 30px;
    margin: 15px;
  }
`

const MapSeperator = styled.div`
  background: ${({ theme }) => theme.primary};
  width: 100%;
  height: 5px;
`

const Property = ({ data: { contentfulProperty: property } }) => {
  // console.log(property)

  return (
    <Layout>
      <SEO
        title={property.seoTitle || property.name}
        description={
          property.seoMetaDescription &&
          property.seoMetaDescription.seoMetaDescription
        }
      />

      <HeroCTAForm
        fluid={property.heroPhoto.fluid}
        variant={property.sector && property.sector.key}
        curveTop={<CurveBulgeBottom variant="white" />}
        curveBottom={<CurveCarveTop variant="primary" />}
        form={<PropertyEnquiryForm />}
      >
        <div className="sector">
          {property.sector && property.sector.subtitle}
        </div>
        <h1 className="name">{property.name}</h1>
        <div className="price">
          From: {`£${property.startingPrice.toLocaleString("en-GB")}`}
        </div>
        <div className="call">
          <a href="tel:+44 203 709 4275" target="_blank" rel="noreferrer">
            Call: +44 (0) 203 709 4275
          </a>
        </div>
        <div className="features">
          {property.highlights &&
            property.highlights.map((highlight, i) => (
              <div key={highlight} className="feature-item">
                <FaCircle className="text-secondary" />
                <div>{highlight}</div>
              </div>
            ))}
        </div>
      </HeroCTAForm>

      <div className="bg-primary text-white text-center py-5">
        <Container>
          <Row>
            <Col md={8} className="mx-auto">
              <h2>{property.headline && property.headline.headline}</h2>
              <HR />

              {property.summary && (
                <div
                  className="lead"
                  dangerouslySetInnerHTML={{
                    __html: property.summary.childMarkdownRemark.html,
                  }}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col>
              <GetDetails />
            </Col>
          </Row>
        </Container>
      </div>
      <CurveCarveBottom variant="primary" />

      <div>
        <Container className="py-4 text-center">
          <Row>
            <Col>
              <h2 className="mb-5">Key Figures</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={4} className="my-5">
              <h3>
                <div className="display-3 mb-4">{`£${property.startingPrice.toLocaleString(
                  "en-GB"
                )}`}</div>
                Starting Price
              </h3>
            </Col>
            <Col lg={4} className="my-5">
              <h3>
                <div className="display-3 mb-4">{property.yield}%</div>
                Net Yield
              </h3>
            </Col>
            <Col lg={4} className="my-5">
              <h3>
                <div className="display-3 mb-4">
                  {property.completionDateQuarter}
                </div>
                Completion Date
              </h3>
            </Col>
          </Row>
        </Container>
      </div>

      <CurveCarveTop variant="light" />
      <div className="bg-light py-5">
        <Container>
          <Row>
            <Col>
              <h2 className="mb-5 text-center">Investment Details</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={6} style={{ borderRight: "2px solid" }}>
              <p>{property.details && property.details.details}</p>

              {property.details && <MarkdownCommon body={property.details} />}
            </Col>
            <Col lg={6} style={{ borderLeft: "2px solid" }}>
              <StyledHighlights>
                {/* <h4 className="mb-4">Features</h4>
                {property.highlights &&
                  property.highlights.map(highlight => (
                    <div key={highlight}>
                      <div>{highlight}</div>

                      <Icon name={property.sector && property.sector.key} />
                    </div>
                  ))}

                <HR className="my-5" /> */}
                <div>
                  <h4 className="my-4">The Numbers</h4>
                  <p>
                    Ground Rent:
                    <strong className="ml-2">{property.groundRent}</strong>
                  </p>
                  <Icon name={property.sector && property.sector.key} />
                  <p>
                    Number of Units:
                    <strong className="ml-2">{property.numberOfUnits}</strong>
                  </p>
                  <Icon name={property.sector && property.sector.key} />
                  <p>
                    Service Charge:
                    <strong className="ml-2">{property.serviceCharge}</strong>
                  </p>
                  <Icon name={property.sector && property.sector.key} />
                  <p>
                    Prices:
                    <strong className="ml-2">{property.prices}</strong>
                  </p>
                  <Icon name={property.sector && property.sector.key} />
                  <p>
                    Leasehold:
                    <strong className="ml-2">{property.lease}</strong>
                  </p>
                </div>
              </StyledHighlights>
            </Col>
          </Row>
          <Row>
            <Col>
              <GetDetails />
            </Col>
          </Row>
        </Container>
      </div>

      <PropertyPhotos photos={property.photos} />

      <MapSeperator />

      <MapBase
        center={{
          lat: property.location.lat,
          lng: property.location.lon,
          text: property.name,
        }}
        poi={property.pointsOfInterest}
      />

      <div>
        {property.propertySpecification && (
          <AccordionSquared
            title="Property Specification"
            variant={property.sector && property.sector.key}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: property.propertySpecification.childMarkdownRemark.html,
              }}
            />
          </AccordionSquared>
        )}
        {property.paymentPlan && (
          <AccordionSquared
            title="Payment Plan"
            variant={property.sector && property.sector.key}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: property.paymentPlan.childMarkdownRemark.html,
              }}
            />
          </AccordionSquared>
        )}
      </div>
      <div className="bg-primary pt-4">
        <GetDetails />
      </div>
      <CurveBulgeBottom variant="primary" />
      <CallMeBackFull />
    </Layout>
  )
}

export const query = graphql`
  query PropertyDetailQuery($id: String) {
    contentfulProperty(id: { eq: $id }) {
      ...PropertyFragment
    }
  }
`

export default Property
